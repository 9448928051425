import React from 'react';
import { Typography, Grid, Container, Card, CardContent, CardMedia  } from '@mui/material';

function AboutPage() {
  const teamMembers = [
    { name: "Alice Lee", role: "Founder & CEO", imgSrc: "/team/alice.jpg", bio: "Alice is a passionate entrepreneur with a background in ..."},
    { name: "Bob Johnson", role: "Lead Developer", imgSrc: "/team/bob.jpg", bio: "Bob is a skilled software engineer with a knack for ..." },
    // ... Add more team members
  ];

  return (
    <Container maxWidth="md">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h3" align="center" gutterBottom>
            Meet the Team
          </Typography>
        </Grid>
        {teamMembers.map((member, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}> 
            <Card>
              <CardMedia
                component="img"
                height="250" // Adjust as needed
                image={member.imgSrc}
                alt={member.name}
              />
              <CardContent>
                <Typography variant="h5" component="div"> 
                  {member.name}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  {member.role}
                </Typography>
                <Typography variant="body2">
                  {member.bio}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default AboutPage;