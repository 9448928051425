import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import password from '../config/secret'

const LoginPage = ({ setIsLoggedIn }) => {
  const [pwd, setPwd] = useState('');
  const fixedPassword = password; // Hardcoded password for demonstration

  const handleLogin = (event) => {
    event.preventDefault(); // This should prevent any default form submission
    if (pwd === fixedPassword) {
      alert('Login successful!');
      setIsLoggedIn(true);
    } else {
      alert('Incorrect password!');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}> {/* onSubmit attached to form */}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={pwd}
            onChange={e => setPwd(e.target.value)}
          />
          <Button
            type="submit" // Ensure this is a submit button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default LoginPage;
