import React, { useEffect, useState } from 'react';
import ImagePanel from '../components/ImagePanel';
import ChatPanel from '../components/ChatPanel';
import { Grid, Container } from '@mui/material';
import '../index.css';
import LoginPage from '../components/LoginPage';
import Navbar from '../components/Navbar';
import { service_conf_dev } from '../config/url_conf_dev';

function DialogueDev() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const image_data = require('../data/demo.json')
  const [imageid, setImageId] = useState(5);
  const [annotationid, setAnnotationId] = useState(0);
  const [messages, setMessages] = useState([{"utter_id": "user_0", "speaker": "NA", "state": "start", "text": "NA", "object_id": null }]); // user utter_id = -1 init object_id with null, which associate
  const [isChatDisabled, setIsChatDisabled] = useState(false);
  const [ttsGenerating, setIsttsGenerating] = useState(false);
  const [isEvaluating, setIsEvaluating] = useState(false);
  const [needShowBox, setNeedShowBox] = useState(false)

  // const sendMessage = (message) => {
  //   const regex = /^(-?\d+),(-?\d+),(-?\d+),(-?\d+)$/;
  //   if (regex.test(message)) {
  //     const [x1, y1, x2, y2] = message.split(',').map(Number);
  //     setCoordinates({x1, y1, x2, y2})
  //   } else {
  //     console.log('Input format is wrong')
  //   }
  // }
  useEffect(() => {
    // if (isChatDisabled) {
    //   console.log('box change, generating TTS')
    //   reuqestTTS(imageid, annotationid)
    // }
    // no need tts for now
  }, [annotationid, imageid]); // listen to usermessage change


  // console.log(image_data)

  // const generateRefAudio()
  const onImgClick = (event) => {
    if (isChatDisabled && !ttsGenerating && !isEvaluating && needShowBox) {
      console.log('Image is clicked')
      const img = event.target;

      // Ensure that we're getting the coordinates from the image
      if (img.tagName !== 'IMG') {
        return;
      }

      // Get the displayed size of the image
      const displayedWidth = img.clientWidth;
      const displayedHeight = img.clientHeight;

      // Get the natural size of the image
      const naturalWidth = img.naturalWidth;
      const naturalHeight = img.naturalHeight;

      // Calculate the scaling factor
      const widthScale = naturalWidth / displayedWidth;
      const heightScale = naturalHeight / displayedHeight;

      // Get the click position within the image
      const bounds = img.getBoundingClientRect();
      const x = (event.clientX - bounds.left) * widthScale;
      const y = (event.clientY - bounds.top) * heightScale;

      // Update the state with the scaled coordinates
      // console.log(x, y)
      const annotation_idx = getAnnoIdx(x, y, image_data[imageid]['annotation'])
      if (annotation_idx >= 0) {
        console.log(image_data[imageid]['annotation'][annotation_idx].description)
        setAnnotationId(annotation_idx)
        setMessages([...messages, {"utter_id": "reference", "speaker": "user", "state": "start", "text": image_data[imageid]['annotation'][annotation_idx].reference, "object_id": messages[messages.length - 1]['object_id']}])
        // setMessages([...messages, { "role": "user", "message": image_data[imageid]['annotation'][annotation_idx].reference }])
      }
    }
  }

  const createCoordinate = (bbox) => {
    // console.log(bbox)
    return { x1: bbox[0], y1: bbox[1], x2: bbox[2], y2: bbox[3] };
  }

  // console.log(image_data)

  const showNextPic = () => {
    var randomidx = Math.floor(Math.random() * image_data.length)
    while (randomidx == imageid && image_data.length > 1) {
      randomidx = Math.floor(Math.random() * image_data.length)
    }
    console.log(randomidx)
    setImageId(randomidx)
    setAnnotationId(0)
  }

  const getAnnoIdx = (x, y, annotations) => {
    console.log(x, y, annotations)
    let idx = 0
    for (let item of annotations) {
      let [x1, y1, x2, y2] = item.bbox;
      if (x >= x1 && x <= x2 && y >= y1 && y <= y2) {
        return idx;
      }
      idx += 1
    }
    return -1; // or a default value indicating not found
  }

  const showNextBbox = () => {
    var randomidx = Math.floor(Math.random() * image_data[imageid]['annotation'].length)
    while (randomidx == annotationid && image_data[imageid]['annotation'].length > 1) {
      randomidx = Math.floor(Math.random() * image_data.length)
    }
    console.log(randomidx)

    setAnnotationId(randomidx)
  }

  return isLoggedIn ? (
    <div>
        {/* <Navbar /> */}
        <Container style={{ display: 'flex', flexDirection: 'row', height: '100vh', width: '100%', padding: 0 }} maxWidth={false}>
      <Grid container spacing={3} style={{ width: '100%', height: '100%' }}>
        <Grid item xs={12} md={8} className='image-panel-grid'>
          <ImagePanel
            src={image_data[imageid]['image_path']}
            alt="Description"
            onImgClick={onImgClick}
            needShowBox={needShowBox}
            setNeedShowBox={setNeedShowBox}
            {...createCoordinate(image_data[imageid]['annotation'][annotationid]['bbox'])}
          />
        </Grid>
        <Grid item xs={12} md={4} className='chat-panel-grid'>
          <ChatPanel image_list={image_data} image_info={image_data[imageid]} image_id={imageid} annotation_id={annotationid}
            showNextBbox={showNextBbox} showNextPic={showNextPic} messages={messages} setMessages={setMessages} isChatDisabled={isChatDisabled} 
            setIsChatDisabled={setIsChatDisabled} isTTSGenerating={ttsGenerating} setIsttsGenerating={setIsttsGenerating} isEvaluating={isEvaluating} 
            setIsEvaluating={setIsEvaluating} setAnnotationId={setAnnotationId} setNeedShowBox={setNeedShowBox} setImageId={setImageId} service_conf={service_conf_dev}
            />
        </Grid>
      </Grid>
    </Container>
    </div>
  ) : <LoginPage setIsLoggedIn={setIsLoggedIn}></LoginPage>;
  
}

export default DialogueDev;
