import { useEffect, useState } from "react";
import { MediaRecorder, register } from "extendable-media-recorder";
import { connect } from "extendable-media-recorder-wav-encoder";

await register(await connect());

const useRecorder = () => {
    const [audioURL, setAudioURL] = useState("");
    const [isRecording, setIsRecording] = useState(false);
    const [recorder, setRecorder] = useState(null);
    const [deviceStatus, setDeviceStatus] = useState('INIT');

    useEffect(() => {
        // Manage recorder state.
        if (isRecording && recorder) {
            recorder.start();
        } else if (!isRecording && recorder) {
            recorder.stop();
        }

        // Obtain the audio when ready.
        const handleData = (e) => {
            setAudioURL(URL.createObjectURL(e.data));
        };

        if (recorder) {
            recorder.addEventListener("dataavailable", handleData);
            return () => recorder.removeEventListener("dataavailable", handleData);
        }
    }, [recorder, isRecording]);

    const startRecording = async () => {
        if (!recorder) {
            try {
                setDeviceStatus('PENDING');
                const newRecorder = await requestRecorder();
                setRecorder(newRecorder);
                setDeviceStatus('GRANTED');
            } catch (err) {
                setDeviceStatus('DENIED');
                console.error("Failed to start recording:", err);
                return;
            }
        }
        setIsRecording(true);
    };

    const stopRecording = () => {
        setIsRecording(false);
    };

    return [audioURL, setAudioURL, isRecording, startRecording, stopRecording, deviceStatus];
};

async function requestRecorder() {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    return new MediaRecorder(stream, { mimeType: "audio/wav" });
}

export default useRecorder;

