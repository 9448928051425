import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button } from '@mui/material'; 

function Navbar() {
  return (
    <AppBar position="static"> {/* Material-UI AppBar */}
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {/* Your App Title/Logo */}
        </Typography>
        <Link to="/" style={{ color: 'white', textDecoration: 'none' }}>Home</Link> 
        <Link to="/about" style={{ color: 'white', textDecoration: 'none', marginLeft: '10px' }}>About</Link>
        {/* Add more links as needed, e.g.,  <Link to="/settings">Settings</Link> */}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;