export const service_conf_dev = {
    "TTS_SPEAK_SERVICE" : {'en': 'http://astarwiz.com:9603/speak', 'zh': 'http://astarwiz.com:9603/speak', 'ma': 'http://astarwiz.com:9603/speak', "ta": 'http://astarwiz.com:9603/speak'},
    "TTS_WAVE_SERVICE" : {'en': 'http://astarwiz.com:9603/wave', 'zh': 'http://astarwiz.com:9603/wave', 'ma': 'http://astarwiz.com:9603/wave', "ta": 'http://astarwiz.com:9603/wave'},
    "EVAL_SERVICE": 'https://astarwiz.com:9202/evaluate',
    "TTS_FILE_SERVICE": 'https://astarwiz.com:9202/tts',
    "DIALOGUE_SERVICE": {"A": "http://astarwiz.com:9205/dialogue_backend", "B": "http://astarwiz.com:9206/dialogue_backend"}, 
    "ASR_SERVICE": "http://astarwiz.com:9898/asr",
    // "DIALOGUE_SERVICE": "https://10.2.56.194:2333/dialogue_backend",
    // "ASR_SERVICE": "https://10.2.56.194:9898/asr"
}

