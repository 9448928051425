import { useState, useCallback, useLayoutEffect } from 'react';

export function useComponentSize(ref) {
    const [size, setSize] = useState({ width: 0, height: 0 });

    const updateSize = useCallback(() => {
        if (ref.current) {
            setSize({ width: ref.current.offsetWidth, height: ref.current.offsetHeight });
        }
    }, [ref]);

    useLayoutEffect(() => {
        updateSize();
        window.addEventListener('resize', updateSize);

        return () => window.removeEventListener('resize', updateSize);
    }, [updateSize]);

    return size;
}