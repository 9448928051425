import React from 'react';
import '../css/ChatPanel.css';

const HighlightErrors = ({ dp_align_dict, reference, language}) => {
    const punctuation = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';

    const renderText = () => {
        let elements = [];
        let inError = false;
        let text_to_display = ""

        const regex = /\w+|\S/g;
        const splited_text = reference.match(regex);
        let idx = 0
        let padding = ' '
        if (language == 'Mandarin') {
            padding = ''
        }

        for (let i = 0; i < splited_text.length; i++) {
       
            if (punctuation.includes(splited_text[i])) {
                elements.push(<span key={idx} className="normalText">{padding + splited_text[i]}</span>);
                continue;
            }
            
            if (dp_align_dict.ERR[idx] === 'I') {
                elements.push(<span key={idx} className="normalText">{padding + splited_text[i]}</span>);
            } else if (dp_align_dict.ERR[idx] !== 'H') {
                inError = true;
                elements.push(<span key={idx} className="errorText">{padding + splited_text[i].toUpperCase()}</span>);
            } else {
                if (inError) {
                    inError = false;
                }
                elements.push(<span key={idx} className="normalText">{padding + splited_text[i]}</span>);
            }
            idx += 1
        }

        return elements;
    };

    return (
        <div className="textContainer">
            {renderText()}
        </div>
    );
};

export default HighlightErrors;
