import React, { useState, useRef, useEffect } from 'react';
import { Button, CircularProgress, TextField, Paper, List, ListItem, Typography, IconButton, Switch, Divider } from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem, Box} from '@mui/material';

import { Send as SendIcon, SkipNext as NextIcon, Https, HowToVoteSharp, PanoramaHorizontalSelectSharp } from '@mui/icons-material';
import axios from 'axios';
import Recorder from './Recorder';
import {v4 as uuidv4} from 'uuid';
// import {service_conf} from '../config/url_conf'
import '../css/ChatPanel.css'
import HighlightErrors from '../utils/HighlightErrors'

const ChatPanel = ({ image_list, image_info, image_id, annotation_id, showNextBbox, showNextPic, messages, setMessages, isChatDisabled, 
  setIsChatDisabled, isTTSGenerating, setIsttsGenerating, isEvaluating,
  setIsEvaluating, setAnnotationId, setNeedShowBox, setImageId, service_conf}) => {
  const [textToEval, setTextToEval] = useState('')
  const [inputValue, setInputValue] = useState('');
  // const [isGenerating, setIsGenerating] = useState(false);
  //const [usermessage, setUsermessage] = useState([]); // used to trigger generation, not sure it's a good approach, it should listen to a event
  const [needGeneration, setNeedGeneration] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [needEval, setNeedEval] = useState(false)
  const [rendering, setRendering] = useState('audio')
  const renderingList = ['both', 'text', 'audio', 'both-teacher']
  const [language, setLanguage] = useState('en')
  const languageList = ['en', 'zh', 'ma', 'ta']
  const [isFinished, setIsFinished] = useState(false)
  const [dialogueEndpoint, setDialogueEndpoint] = useState('A')
  const dialogue_endpoint_lst = Object.keys(service_conf['DIALOGUE_SERVICE'])
  // const [isEvaluating, setIsEvaluating] = useState(false)
  const messagesEndRef = useRef(null);
  // const url = service_conf['DIALOGUE_SERVICE']
  const asrutl = service_conf['ASR_SERVICE']

  useEffect(() => {
    if (!isChatDisabled && needGeneration) {
      console.log('Need to generate')
      getNextQuestion();
      setNeedGeneration(false)
    }
  }, [needGeneration]); // listen to usermessage change
  

  const handleStartRecording = () => {
    setIsRecording(true);
  }

  const requestTTS = async (text, lang) => {
    setIsttsGenerating(true);
    // console.log('Start TTS.')
    let params = {}
    if (lang == 'en') {
      params = {
        language: lang, 
        speed: 1.1,
        speaker: 'MS',
        text: text
      }
    } else if (lang == 'ma') {
      params = {
        language: lang, 
        speed: 1.0,
        speaker: 'msFemale',
        text: text
      }
    } else if (lang == 'ta') {
      params = {
        language: lang, 
        speed: 1.1,
        speaker: 'ta_female1',
        text: text
      }
    } else {
      params = {
        language: lang, 
        speed: 1.1,
        speaker: 'childChinese2',
        text: text
      }
    }
    // const formData = {};
    // formData.append("language", "zh")
    // formData.append('speed', 1.0)
    // console.log(formData.entries())
    // console.log(image_data[imageid]['annotation'][annotationid])
    // formData.append('text', )
  //   for (var pair of formData.entries()) {
  //     console.log(pair[0]+ ', ' + pair[1]); 
  // }
    let rPath = null;
    try {
      // automatically send message
      await axios.get(service_conf['TTS_SPEAK_SERVICE'][lang], {params: params}).then((response) => {
        if (response.status === 200) { //change status code
          // setMessages([...messages, {"role": "audio", "message": response.data}]);
          rPath = response
          console.log(response)
        }
      });
    } catch (err) {
      console.log('test xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')
      console.log('Failed to run TTS');
      console.log(err)
      setIsttsGenerating(false);
      return ;
    }
    console.log(rPath)
    // const waveForm = new FormData();
    // waveForm.append("file", rPath.data);
    const wave_params = {file: rPath.data};

    try {
      console.log("file to fetch: " + rPath.data)
      // automatically send message
      const last_message = messages[messages.length - 1]
      const update_message = {...last_message, "utter_id": "audio", "speaker": "sysaudio", "state": null, "text": service_conf['TTS_WAVE_SERVICE'][lang] + '?file=' + rPath.data, "object_id": messages[messages.length - 1]['object_id']} 
      setMessages([...messages, update_message]);

      
    } catch (err) {
      console.log('Failed to fetch tts audio');
      console.log(err)
      setIsttsGenerating(false);
    }
    setIsttsGenerating(false);
  }

  useEffect(() => {
    // if (messages.length == 1) setNeedShowBox(false);
    console.log('message updated')
    console.log(messages[messages.length - 1]["object_id"])
    console.log('need eval: ' + needEval)
    console.log(messages)
    if (messages.length > 0 && messages[messages.length - 1]["object_id"] != null && !needEval){ // Do not change if not null
      console.log('update annotation')
      console.log(messages[messages.length - 1]["object_id"] == null)
      setAnnotationId(parseInt(messages[messages.length - 1]["object_id"]))
      setNeedShowBox(messages[messages.length - 1]["object_id"] != null); 
    }

    // if (messages.length > 0 && messages[messages.length - 1].speaker === "useraudio" && needEval && textToEval.length > 0) {
    //   evalSpeechAndHighlight(messages[messages.length - 1]['text'], textToEval); // last message is user audio
    // }
    // 
  }, [messages]); // Dependency array includes 'messages'

  useEffect(() => {
    if (messages.length > 0 && messages[messages.length - 1].state == '#END#' && !isFinished) {
      setIsFinished(true);
      console.log('setting text to eval: ' + messages[messages.length - 1].highlighter_ref)
      // setTextToEval(messages[messages.length - 1].highlighter_ref)
      // setMessages([...messages, {"utter_id": "notification", "speaker": "notification", "state": null, "text": 'Click DONE for next image', "object_id": messages[messages.length - 1]['object_id']}])
      requestTTS(messages[messages.length - 1].text, language)
      // setNeedEval(true)
      setNeedShowBox(false)
    }

    if (messages.length > 0 && messages[messages.length - 1].speaker === 'system') {
      requestTTS(messages[messages.length - 1].text.toLowerCase(), language) 
    }
  }, [messages]);

  const handleRecordingComplete = (audioURL) => {
    // Save the audioBlobURL and the associated UUID as needed.
    // For this example, just console log them.
    setIsRecording(false);
    console.log(`Audio URL: ${audioURL}`);
    if (!isChatDisabled && !isFinished) {
      requestASR(audioURL, language);
    } else {
      const last_message = messages[messages.length - 1]
      const update_message = {...last_message, "utter_id": "audio", "speaker": "useraudio", "state": null, "text": audioURL, "object_id": messages[messages.length - 1]['object_id']}
      setMessages([...messages, update_message])
      // setMessages([...messages, {"role": "useraudio", "message": audioURL}]);
    }

  };

  // const evalSpeechAndHighlight = async (audioURL, text) => {
  //   if (textToEval.length > 0) {
  //     console.log(messages)
  //     setIsEvaluating(true);
  //     const file_id = uuidv4()
  //     const evalUrl = service_conf['EVAL_SERVICE']
  //     const ttsUrl = service_conf['TTS_FILE_SERVICE']
  //     const text = textToEval
  //     const lang = language
  //     const data_id = uuidv4()

  //     const audioResponse = await fetch(audioURL);
  //     if (!audioResponse.ok) {
  //         throw new Error(`Audio fetch failed: ${audioResponse.statusText}`);
  //     }
  //     const audioBlob = await audioResponse.blob();
  //     // Optionally, save this blob to a temporary file and manually check if it's corrupted.
  //     const file = new File([audioBlob], file_id + '.wav', { type: "audio/wav" });

  //       if (!(file instanceof File)) {
  //         throw new Error("Audio file conversion failed");
  //     }
      
  //   // Optionally, log the file size and type for debugging
  //   console.log(`File size: ${file.size}, File type: ${file.type}`);

  //     // const file = await fetch(audioURL).then((r) => r.blob()).then((blob) => new File([blob], file_id + '.wav', {type: "audio/wav"}))
  //     const formData = new FormData();
  
  //     formData.append("file", file);
  //     formData.append("text", text);
  //     formData.append("lang", lang);
  //     formData.append("save_data", JSON.stringify(false));
  //     formData.append('local_txt', JSON.stringify(false))
  //     formData.append("data_id", data_id);
  //     console.log('sending eval request')
  //     await axios.get('https://api.ipify.org/?format=json').then(
  //       response => {
  //         console.log(response.data)
  //         formData.append("ip", response.data.ip)
  //       }
  //     )
  //     let evalResult = null;
  //     await axios.post(evalUrl, formData).then((
  //       response => {
  //         evalResult = response.data.raw_sdk_json;
  //         console.log(response);
  //       }
  //     )).catch((e) => {
  //       console.log(e)
  //       setIsEvaluating(false);
  //       return;
  //     })
  //     console.log(evalResult)
  //     // console.log(evalResult.aio_dp_align)
  //     // const correctionString = getErrorSpan(evalResult.aio_dp_align).join(' ')
  //     // console.log(correctionString)
      
  //     try {
  //       setMessages([...messages, {"utter_id": "eval_result", "speaker": "teacher", "state": null, "text": evalResult.aio_dp_align, "object_id": messages[messages.length - 1]['object_id']}, 
  //       {"utter_id": "highlighter", "speaker": "highlighter", "state": null, "text": ttsUrl + '?file_id=' + file_id, "object_id": messages[messages.length - 1]['object_id']}])
  //     } catch {
  //       console.log('Eval error')
  //     }
  //     // setMessages([...messages, {"role": "teacher", "message": correctionString}])
  
  //     // setMessages([...messages, {"role": "teacher", "message": evalResult.aio_dp_align}, {"role": "highlighter", "message": ttsUrl + '?file_id=' + file_id}])
  //     console.log(messages)
  //     setIsEvaluating(false);
  //   }
  // }
  function parsePythonListString(listString) {
    // Remove the outer quotes and square brackets
    const cleanedString = listString.replace(/^['"]|['"]$/g, '').replace(/^\[|\]$/g, '');
    
    // Split the string by comma, but not within quotes
    const items = cleanedString.match(/('.*?'|".*?"|\S+)/g) || [];
    
    // Remove quotes from each item, trim whitespace, and remove all commas
    return items.map(item => item.replace(/^['"]|['"]$/g, '').replace(/,/g, '').trim());
  }
  
  const requestASR = async (url, lang) => {
    if (!isChatDisabled){
      const file_id = uuidv4()
      const file = await fetch(url).then((r) => r.blob()).then((blob) => new File([blob], file_id + ".wav", { type: "audio/wav" }));
      const formData = new FormData();
      formData.append("file", file)

      if (lang != 'ta') {

        const keywordListStrAsPrompt = parsePythonListString(messages[1]["one_keyword_list"])

        const keywordListStrAsPromptJoined = keywordListStrAsPrompt.length < 1 
          ? "" 
          : keywordListStrAsPrompt.join('.').replace(/\.\./g, '. ');
        
        // console.log('ASR prompt' + messages[1]["image_content"])
        formData.append("prompt", keywordListStrAsPromptJoined)
      }

      if (lang === 'ma') {
        formData.append("language", 'ms')
      } else {
        formData.append("language", lang)
      }
  
      setIsProcessing(true)
      try {
        // automatically send message
        await axios.post(asrutl, formData).then((response) => {
          if (response.status === 200 && response.data.text) { //change status code
            const new_utt_id = assignUttId(messages)
            const last_message = messages[messages.length - 1]
            const update_message_1 = {...last_message, "utter_id": "audio", "speaker": "useraudio", "state": null, "text": url, "object_id": messages[messages.length - 1]['object_id']}
            const update_message_2 = {...last_message, "utter_id": new_utt_id, "speaker": "user", "state": null, "text": response.data.text, "object_id": messages[messages.length - 1]['object_id']}
            setMessages([...messages, update_message_1, update_message_2])
            // setMessages([...messages, {"role": "useraudio", "message": url}, {"role": "user", "message": response.data.text}]);
            setNeedGeneration(!needGeneration)
          }
        });
        setIsProcessing(false)
      } catch (err) {
        console.log('Failed to run asr');
        setIsProcessing(false)
      }
    }

  }

  const assignUttId = (data) => {
    let lastIdNumber = 0;

    // Iterate in reverse order
    for (let i = data.length - 1; i >= 0; i--) {
        if (data[i].speaker === 'user') {
            // Extract the number from the utt_id and convert it to an integer
            console.log(data[i])
            const idParts = data[i].utter_id.split('_');
            lastIdNumber = parseInt(idParts[1], 10);
            break; // Exit the loop once the last user utt_id is found
        }
    }

    // Increment the number for the next utt_id
    const nextIdNumber = lastIdNumber + 1;

    // Return the next utt_id in the format 'user_X'
    return `user_${nextIdNumber}`;
  }

  const handleSend = () => {
    if (inputValue.trim()) {
      console.log('handle send');
  
      // Update the messages state and then fetch the next question
      const new_utt_id = assignUttId(messages)
      const last_message = messages[messages.length - 1]
      const update_message = {...last_message, "utter_id": new_utt_id, "speaker": "user", "state": messages[messages.length - 1]['state'], "text": inputValue, "object_id": messages[messages.length - 1]['object_id']}
      setMessages([...messages, update_message])
      // setMessages([...messages, {"role": "user", "message": inputValue}]);
      setNeedGeneration(!needGeneration)
      setInputValue('');
      console.log(messages);
      // console.log(usermessage)
    }
  };

  // const postQuestion = (question) => {
  //   console.log('postQuestion')
  //   setMessages([...messages, {"role": "agent", "message": question}]);
  // };

  const clearMessages = () => {
    if (window.confirm("You are done with this picture, clear and go next?")) {
      // showNextPic()
      setMessages([{"utter_id": "user_0", "speaker": "NA", "state": "start", "text": "NA", "object_id": null}]);
      setNeedGeneration(!needGeneration)
      setIsFinished(false);
      // setNeedEval(false);
      // setTextToEval(false)
    }
  }

  const handleNextPic = () => {
      console.log('handleNextPic')
      showNextPic()
      clearMessages()
      setNeedGeneration(true)
      setAnnotationId(null)
  }

  // const handleNextBBox = () => {
  //   console.log('handleNextBBox')
  //   showNextBbox()
  //   // clearMessages()
  //   setNeedGeneration(!needGeneration)
  // }


  const getNextQuestion = async () => {
    if (!isFinished) {
      console.log('Getting next question..')
      var data = new FormData();
      console.log(messages)
      // console.log(data)
      // const message_sent = messages.filter((entry) => entry.role == 'user' || entry.role == 'agent') //filter out audio message when request
      console.log('Message sent' + JSON.stringify(messages))
      data.append("dialogue_context", JSON.stringify(messages));
      data.append("object_annotation", JSON.stringify(image_info['annotation']));
      data.append("image_id", JSON.stringify(image_info['image_id']))
      data.append('language', language)
      setIsProcessing(true)
      try {
        await axios.post(service_conf['DIALOGUE_SERVICE'][dialogueEndpoint], data).then((response) => {
          if (response.status === 200) { //change status code
              // postQuestion(response.data.backend_output); //adjust json format
              console.log(response.data.backend_output)
              setMessages(response.data.backend_output)
              console.log('backend: ' + JSON.stringify(messages))
          }
        });
  
      } catch (err) {
        console.log('Failed to get messages.' + err);
      }
  
      setIsProcessing(false);
    }
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  React.useEffect(scrollToBottom, [messages]);

  const renderChatElement = (key, msg) => {
    const role = msg['speaker'];
    const text = msg['text']
    // Define styles for different roles
    const userStyle = { 
      fontWeight: 'normal', 
      color: '#161D15', // Neutral color for text
      backgroundColor: '#eceff1', // Light greyish-blue background, similar to the example
      borderRadius: '18px', // Rounded corners for speech bubble effect
      padding: '10px 15px', // Padding around text for bubble effect
      maxWidth: '80%', // Maximum width for messages
      fontFamily: 'Arial, sans-serif', // Sans-serif font for a modern look
      marginLeft: 'auto' // Align to the right
    };
    const agentStyle = { 
      fontWeight: 'normal', 
      color: 'white', // Neutral color for text
      backgroundColor: '#2a66bb', // White background for the agent
      borderRadius: '18px', // Rounded corners for speech bubble effect
      padding: '10px 15px', // Padding around text for bubble effect
      maxWidth: '80%', // Maximum width for messages
      fontFamily: 'Arial, sans-serif' // Sans-serif font for a modern look
      // boxShadow: '0px 1px 2px rgba(0,0,0,0.2)' // Subtle shadow for depth
    };
    // const userAudioStyle = { fontWeight: 'bold', color: 'purple', };
    // const agentAudioStyle = { fontWeight: 'bold', color: 'purple'};
    const highlighterStyle = {    fontWeight: 'normal', 
      color: 'white', // Neutral color for text
      backgroundColor: '#C09D9B', // White background for the agent
      borderRadius: '18px', // Rounded corners for speech bubble effect
      padding: '10px 15px', // Padding around text for bubble effect
      maxWidth: '80%', // Maximum width for messages
      fontFamily: 'Arial, sans-serif' // Sans-serif font for a modern look };
    }
  
    // Render elements based on the role
    switch(role) {
      case 'user':
        return (
          <ListItem style={{justifyContent: "flex-end", marginBottom: '10px', display: 'flex'}}>
            <Typography variant="body1" style={userStyle}>
              {text}
            </Typography>
          </ListItem>
        );

      case 'system':
        return (
          <ListItem style={{justifyContent: "flex-start", marginBottom: '10px', display: 'flex'}}>
            <Typography variant="body1" style={agentStyle}>
              {text}
            </Typography>
          </ListItem>
        );
  
      case 'audio':
        return (
          <ListItem style={{justifyContent: "flex-start"}}>
          <div>
            <Typography variant="body1" style={agentStyle}>
              {`${role}: Click to listen to the reference`}
            </Typography>
            <audio controls autoPlay src={text} onError={(e) => console.log(e)} />
          </div>
          </ListItem>
        );
  
      case 'useraudio':
        return (
          <ListItem style={{justifyContent: "flex-end"}}>
          <div style={userStyle}>
            <Typography variant="body1" align='right'>
              {`User (Audio): `}
            </Typography>
            <audio controls src={text} onError={(e) => console.log(e)} />
          </div>
          </ListItem>
        );
  
      case 'highlighter': 
        return (
          <ListItem style={{justifyContent: "flex-start"}}>
          <div style={highlighterStyle}>
            <Typography variant="body1">
              {`Highlighter: Your correction:`}
            </Typography>
            <audio controls src={text} onError={(e) => console.log(e)} />
          </div>
          </ListItem>
        );
        
      case 'sysaudio': 
        return (
          <ListItem style={{justifyContent: "flex-start"}}>
          <div style={agentStyle}>
            <Typography variant="body1">
              {`System (Audio): `}
            </Typography>
            <audio controls autoPlay src={text} onError={(e) => console.log(e)} />
          </div>
          </ListItem>
        );
      case 'notification': 
        return (
          <ListItem style={{justifyContent: "flex-end"}}>
          <div style={highlighterStyle}>
            <Typography variant="body1">
              {`Notification: ` + text}
            </Typography>
          </div>
          </ListItem>
        );
      case 'teacher': 
      return (
        // <div>
        //   {msg["message"] ? (
        //     <Typography variant="body1" style={highlighterStyle}>
        //       {`Teacher: Please take note ` + msg["message"]}
        //     </Typography>
        //   ) : (
        //     <Typography variant="body1" style={highlighterStyle}>
        //       {`Teacher: You are correct!`}
        //     </Typography>
        //   )}
        // </div>
        <ListItem style={{justifyContent: "flex-end"}}>
        <HighlightErrors dp_align_dict={text} reference={textToEval} language={image_info.lang}></HighlightErrors>
        </ListItem>
      );
      
      // Consider adding a default case for handling unexpected roles
    }
  }
  

  // React.useEffect(getNextQuestion, [])
  return (
    <Paper elevation={3} style={{height: '100%', display: 'flex', flexDirection: 'column', position: 'relative'}}>

    <Box marginTop="0.5em" marginLeft="0.7em" marginRight="0.7em">
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>

      <FormControl style={{ flex: 1, marginRight: '10px' }} fullWidth>
        <InputLabel id="demo-simple-select-label-1">Image</InputLabel>
        <Select
            labelId="demo-simple-select-label-1"
            id="demo-simple-select-1"
            label="Image"
            value={image_list[image_id].image_id}
            onChange={(event)=>{
              clearMessages();
              console.log(event);
              console.log(event.target.value + ' is selected.');
              setImageId(image_list.findIndex(obj => obj.image_id === event.target.value))
              // setAnnotationId(null)
            }}
        >
       {image_list.sort((a, b) => a.image_id.localeCompare(b.image_id)).map((option, index) => (
    <MenuItem key={index} value={option.image_id}>
        {option.image_id}
    </MenuItem>
))}
        </Select>
      </FormControl>

      <FormControl style={{ flex: 1 }} fullWidth>
        <InputLabel id="demo-simple-select-label-2">Rendering</InputLabel>
        <Select
            labelId="demo-simple-select-label-2"
            id="demo-simple-select-2"
            label="Rendering"
            value={rendering}
            onChange={(event)=>{
              console.log(event.target.value)
              setRendering(event.target.value)
            }}
        >
          {renderingList.map((option, index) => (
              <MenuItem key={index} value={option}>
                  {option}
              </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl style={{ flex: 1 , marginLeft: '10px' }} fullWidth>
        <InputLabel id="demo-simple-select-label-2">Language</InputLabel>
        <Select
            labelId="demo-simple-select-label-2"
            id="demo-simple-select-2"
            label="Rendering"
            value={language}
            onChange={(event)=>{
              console.log(event.target.value)
              setLanguage(event.target.value)
              clearMessages()
            }}
        >
          {languageList.map((option, index) => (
              <MenuItem key={index} value={option}>
                  {option}
              </MenuItem>
          ))}
        </Select>
      </FormControl>

           
      <FormControl style={{ flex: 1 , marginLeft: '10px' }} fullWidth>
        <InputLabel id="demo-simple-select-label-3">Endpoint</InputLabel>
        <Select
            labelId="demo-simple-select-label-3"
            id="demo-simple-select-3"
            label="Dialogue-endpoint"
            value={dialogueEndpoint}
            onChange={(event)=>{
              console.log(event.target.value)
              setDialogueEndpoint(event.target.value)
              clearMessages()
            }}
        >
          {dialogue_endpoint_lst.map((option, index) => (
              <MenuItem key={index} value={option}>
                  {option}
              </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
    
    

    <Box display="flex" alignItems="center" marginBottom="0em" justifyContent="left">
    <Button
      color="primary" 
      onClick={clearMessages}
      style={{ marginRight: '1em' }} // Add spacing to the right of the first button
    >
      Clear
    </Button>

    <Button
      color="primary"
      onClick={handleNextPic}
      style={{ marginLeft: '1em' }} // Add spacing to the left of the second button
    >
      Next Picture
    </Button>
  </Box>
    </Box>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '1px', borderBottom: '1px solid #ddd'}}>
      </div>
      <List style={{overflowY: 'auto', flexGrow: 1, flexBasis: '0'}}>
        {messages.filter((msg, index)=> {

          if (rendering == 'both') {
            return true;
          } else if (rendering == 'text') {
            return new Set(['user', 'system']).has(msg['speaker'])
          } else if (rendering == 'audio') {
            return new Set(['useraudio', 'sysaudio', 'highlighter']).has(msg['speaker'])
          } else if (rendering == 'both-teacher') {
            return new Set(['sysaudio', 'highligher', 'useraudio', 'system']).has(msg['speaker'])
          }else {
            return true
          }
           
        }).map((msg, index) => (
            renderChatElement(index, msg)
        ))}
        <div ref={messagesEndRef} />
      </List>
      
    {
      (isTTSGenerating || isEvaluating || isProcessing) && (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 2, // to make sure it's on top
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          // Adding a backdrop to dim the background
          backdropFilter: 'blur(4px)',
        }}>
          <CircularProgress size={80} style={{ color: '#FF5733' }} /> {/* Larger and colored progress circle */}
          {/* <Typography 
            position='absolute'
            style={{
              color: 'black', // More visible text color
              fontWeight: 'bold', // Bold text
              fontSize: '1.2rem' // Larger font size
            }}
          >
            Computing
          </Typography> */}
        </div>
      )
    }
      <div style={{padding: '10px', borderTop: '1px solid #ddd', display: 'flex', alignItems: 'center', position: 'relative'}}>
        <TextField 
          variant="outlined"
          fullWidth
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          placeholder="Type a message..."
          disabled={isProcessing || isRecording || isChatDisabled}
          onKeyPress={e => e.key === 'Enter' && handleSend()}
        />
        <IconButton 
          color="primary"
          onClick={handleSend}
          disabled={isChatDisabled}
          style={{marginLeft: '10px'}}
        >
          <SendIcon />
        </IconButton>
        {/* <IconButton 
          color="primary"
          disabled={isChatDisabled}
          onClick={handleNextBBox}
        >
          <NextIcon />
        </IconButton> */}
      </div>

      <Recorder handleAudio={handleRecordingComplete} handleStart={handleStartRecording} isTTSGenerating={isTTSGenerating} isEvaluating={isEvaluating} isFinished={isFinished}></Recorder>
    </Paper>
  );
}

export default ChatPanel;
