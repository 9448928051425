// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
.normalText {
    font-size: 16px;
  }
  
  .errorText {
    font-size: 20px;
    color: red; /* Additional styling for error text */
    text-shadow: 1px 1px 2px black; /* Optional shadow for more emphasis */
  }
  
  .textContainer {
    overflow: auto; /* or 'scroll' if you always want scrollbars */
    max-height: 200px; /* or any other value */
    /* Other styling as needed */
  }
  `, "",{"version":3,"sources":["webpack://./src/css/ChatPanel.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,UAAU,EAAE,sCAAsC;IAClD,8BAA8B,EAAE,sCAAsC;EACxE;;EAEA;IACE,cAAc,EAAE,8CAA8C;IAC9D,iBAAiB,EAAE,uBAAuB;IAC1C,4BAA4B;EAC9B","sourcesContent":["/* styles.css */\n.normalText {\n    font-size: 16px;\n  }\n  \n  .errorText {\n    font-size: 20px;\n    color: red; /* Additional styling for error text */\n    text-shadow: 1px 1px 2px black; /* Optional shadow for more emphasis */\n  }\n  \n  .textContainer {\n    overflow: auto; /* or 'scroll' if you always want scrollbars */\n    max-height: 200px; /* or any other value */\n    /* Other styling as needed */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
