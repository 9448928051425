import React, { useRef, useState, useEffect } from 'react';
import { Paper } from '@mui/material';
import { NoEncryption } from '@mui/icons-material';

const ImagePanel = ({ src, alt, x1, y1, x2, y2, onImgClick, needShowBox, setNeedShowBox}) => {
    const [scale, setScale] = useState(1);
    const [horizontalOffset, setHorizontalOffset] = useState(0);
    const [verticalOffset, setVerticalOffset] = useState(0);
    const imgRef = useRef();
    const [isImageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        setImageLoaded(false);
    }, [src])
    useEffect(() => {
        const computeScale = () => {
            // console.log(scale)
            // console.log(x1, y1, x2, y2)
            if (isImageLoaded) {
                const displayedWidth = imgRef.current.clientWidth;
                const naturalWidth = imgRef.current.naturalWidth;
                const displayedHeight = imgRef.current.clientHeight;
                const containerHeight = imgRef.current.parentNode.clientHeight;
                const containerWidth = imgRef.current.parentNode.clientWidth;

                setHorizontalOffset((containerWidth - displayedWidth) / 2);
                setVerticalOffset((containerHeight - displayedHeight) / 2);
                setScale(displayedWidth / naturalWidth);
                console.log('Current scale ' + scale)
           }
        };

        computeScale();
        window.addEventListener('resize', computeScale);
        return () => window.removeEventListener('resize', computeScale);
    }, [isImageLoaded]);

    const panelStyle = {
        display: 'flex',
        alignItems: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        justifyContent: 'center',
        height: '100%',
        // maxWidth: '50%',
        // flex: 3,
        // padding: '20px',
        // margin: '20px',
    };

    const imgContainerStyle = {
        position: 'relative',  // make this div a positioning context
        width: '100%',         // take the full width of parent
        height: '100%',
        display: 'flex',  // Added
        alignItems: 'center',  // Added
        justifyContent: 'center',  // Added
    };

    const imgStyle = {
        maxWidth: '100%',
        height: 'auto',
        display: 'block',
        position: 'center',
        borderRadius: '4px',  // Gives a rounded edge to the image
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'  // A slight shadow to give depth
    };
    // const rectangleStyle = {
    //     position: 'absolute',
    //     top: `${y1 * scale + verticalOffset}px`,
    //     left: `${x1 * scale + horizontalOffset}px`,
    //     width: `${(x2 - x1) * scale}px`,
    //     height: `${(y2 - y1) * scale}px`,
    //     border: '4px solid red',  // Increased border width
    //     boxSizing: 'border-box',
    //     animation: 'fadeInOut 1s infinite'  // Bouncing animation
    // };

    const rectangleStyle = {
        position: 'absolute',
        top: `${y1 * scale + verticalOffset}px`,
        left: `${x1 * scale + horizontalOffset}px`,
        width: `${(x2 - x1) * scale}px`,
        height: `${(y2 - y1) * scale}px`,
        border: '6px solid #2a66bb', // Keep the solid border
        boxSizing: 'border-box',
        animation: 'fadeInOut 1.2s infinite',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)', // Add shadows for 3D effect
        transform: 'translateY(-2px)', // Slightly raise the box to enhance 3D effect
        borderRadius: '8px' // Optional: rounded corners can enhance the 3D effect
    };

    
    // Bouncing keyframes animation
    const bouncingAnimation = `
      @keyframes bouncing {
        0%, 20%, 50%, 80%, 100% {
            transform: translateY(0);
        }
        40% {
            transform: translateY(-5px);
        }
        60% {
            transform: translateY(-3px);
        }
      }
    `;

    const fadeInOutAnimation = `
    @keyframes fadeInOut {
        0%, 100% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
    }
`;

    const forwardClickToImage = (e) => {
        // You may need to adjust this selector to match your image element
        const image = document.querySelector('#display-img');
        if (image) {
          // Extract the coordinates from the original event
          const { clientX, clientY } = e;
          // Create a new click event with these coordinates
          var clickEvent = new MouseEvent("click", {
            bubbles: true,
            cancelable: false,
            clientX, // Pass the x coordinate
            clientY, // Pass the y coordinate
            view: window
          });
          // Dispatch it to the image element
          image.dispatchEvent(clickEvent);
        }
      };
      

    return (
        <Paper elevation={3} style={panelStyle}>
            <div style={imgContainerStyle} key={src}>
                {
                    (   
                        needShowBox ?  <div style={rectangleStyle} onClick={forwardClickToImage}></div> : ""
                    )
                }
                <img id='display-img' src={src} alt={alt} style={imgStyle} ref={imgRef} onLoad={() => {
                    setImageLoaded(true)
                    console.log('image loaded')}} onClick={onImgClick}/>
            </div>
            <style>{fadeInOutAnimation}</style>
        </Paper>
    );
}

export default ImagePanel;
